html:not(.--in-storyblok) {
  [data-animate] {
    opacity: 0;
    transform: translateY(80px);
    transition:
      825ms transform,
      825ms opacity;
  }

  [data-animate][data-animated] {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (prefers-reduced-motion) {
  html:not(.--in-storyblok) [data-animate] {
    opacity: 1;
    transform: translateY(0);
    transition-duration: 0s;
  }
}
