:root {
  /* Sizes */
  --page-max-width: 1120px;
  --page-horizontal-padding: 24px;
  --page-left-padding: var(--page-horizontal-padding);
  --page-left-padding: calc(
    var(--page-horizontal-padding) + env(safe-area-inset-left)
  );
  --page-right-padding: var(--page-horizontal-padding);
  --page-right-padding: calc(
    var(--page-horizontal-padding) + env(safe-area-inset-right)
  );
  --header-height: 146px;
  --section-gap: 80px;
  --page-top-padding: calc(var(--header-height) + 88px);

  /* Font families */
  --font-family-title: var(--font-pt-serif);
  --font-family-text: var(--font-montserrat);

  /* Font sizes */
  --font-size-56: 56px;
  --font-size-40: 40px;
  --font-size-16: 16px;
  --font-size-14: 14px;

  /* Z-indexes */
  --z-index-content: 10;
  --z-index-header-background: 20;
  --z-index-hero: 30;
  --z-index-header: 40;
  --z-index-mobile-menu: 50;
  --z-index-mobile-menu-button: 60;
  --z-index-dropdown: 70;

  /* Colors */
  --white: #fff;
  --white-rgb: 255, 255, 255;
  --black: #000;
  --black-rgb: 0, 0, 0;
  --black-text: #1e1f20;
  --black-text-rgb: 30, 31, 32;
  --dark: #161616;
  --dark-rgb: 22, 22, 22;
  --light-grey: #f5f5f5;
  --grey: #5b616e;
  --grey-rgb: 91, 97, 110;
  --grey-500: #6b7280;
  --grey-100: #f3f4f6;
  --grey-100-rgb: 243, 244, 246;
  --primary-500: #01003e;
  --primary-600: #02004e;
  --primary-700: #120039;
  --gold-500: #b5a16f;
  --gold-600: #e1bf6b;
}

@media (--header-breakpoint) {
  :root {
    /* Sizes */
    --header-height: 80px;
    --page-top-padding: calc(var(--header-height) + 30px);
  }
}

@media (--tablet) {
  :root {
    /* Sizes */
    --section-gap: 60px;

    /* Font sizes */
    --font-size-56: 40px;
    --font-size-40: 32px;
  }
}

@media (--mobile) {
  :root {
    /* Sizes */
    --section-gap: 40px;
    --page-top-padding: calc(var(--header-height) + 10px);

    /* Font sizes */
    --font-size-56: 32px;
    --font-size-40: 24px;
  }
}
